import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iReleaseNoteRead } from 'datamodel';

const initialState: iReleaseNoteRead[] = [];

export const releaseNotesReadSlice = createSlice({
  name: 'releaseNotesRead',
  initialState,
  reducers: {
    setReleaseNotesValue: (state, action: PayloadAction<iReleaseNoteRead[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setReleaseNotesValue, reset } = releaseNotesReadSlice.actions;

export default releaseNotesReadSlice.reducer;
