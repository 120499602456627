import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import {
  iAPIHouseSettings,
  iCustomer,
  iCustomerNews,
  iDepartment,
  iHomeCare,
  iHouse,
  iLeasingCompany,
  iNews,
  iQualification,
  iReleaseNote,
} from 'datamodel';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';

export const getCustomerRefById = (id: string): DocumentReference<DocumentData> | false => {
  const customerId = id;
  if (!customerId) return false;
  const customer = doc(db, 'customers', customerId);
  return customer;
};

export const getHousesRef = (customerId: string) => {
  const customerRef = getCustomerRefById(customerId);
  if (!customerRef) return false;
  const houses = collection(customerRef, 'houses');
  return houses;
};

export const getHouseRefById = (customerId: string, houseId: string): DocumentReference<DocumentData> | false => {
  if (!houseId) return false;
  const house = doc(db, 'customers', customerId, 'houses', houseId);
  return house;
};

export const updateAHouseById = async (customerId: string, id: string, value: Partial<iHouse>): Promise<void> => {
  const houseRef = getHouseRefById(customerId, id);
  if (!houseRef) return;
  const res = await updateDoc(houseRef, value);
  return res;
};

export const getOffersRefByHouseId = (
  customerId: string,
  houseId: string,
): CollectionReference<DocumentData> | false => {
  const houseOffers = collection(db, 'customers', customerId, 'houses', houseId, 'offers');
  return houseOffers;
};

export const getOffersCountByHouseId = async (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return 0;
  const houseOffersRef = getOffersRefByHouseId(customerId, houseId);
  if (!houseOffersRef) return 0;
  const count = await getCountFromServer(houseOffersRef);
  return count.data().count || 0;
};

export const getPoolItemsRefByHouseId = (
  customerId: string,
  houseId: string,
): CollectionReference<DocumentData> | false => {
  if (!customerId || !houseId) return false;
  const housePoolItems = collection(db, 'customers', customerId, 'houses', houseId, 'poolItems');
  return housePoolItems;
};

export const getPoolItemsCountByHouseId = async (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return 0;
  const housePoolItemsRef = getPoolItemsRefByHouseId(customerId, houseId);
  if (!housePoolItemsRef) return 0;
  const count = await getCountFromServer(housePoolItemsRef);
  return count.data().count || 0;
};

export const getUsersRef = () => {
  const users = collection(db, 'users');
  return users;
};

export const getUsersCollectionCount = async () => {
  const usersRef = getUsersRef();
  if (!usersRef) return 0;
  const count = await getCountFromServer(usersRef);
  return count.data().count || 0;
};

export const getInternalUserCount = async () => {
  const usersRef = getUsersRef();
  if (!usersRef) return 0;
  const q = query(usersRef, where('isInternalUser', '==', true));
  const count = await getCountFromServer(q);
  return count.data().count || 0;
};

export const getLeasingCompaniesRef = () => {
  const leasingCompanies = collection(db, 'leasingCompanies');
  return leasingCompanies;
};

export const getLeasingCompaniesCollection = async () => {
  const leasingCompaniesRef = getLeasingCompaniesRef();
  if (!leasingCompaniesRef) return false;
  const docs = await getDocs(leasingCompaniesRef);
  return docs;
};

export const getLeasingCompanyRefById = (id: string): DocumentReference<DocumentData> | false => {
  const leasingCompanyId = id;
  if (!leasingCompanyId) return false;
  const leasingCompany = doc(db, 'leasingCompanies', leasingCompanyId);
  return leasingCompany;
};

export const updateLeasingCompanyById = async (id: string, value: Partial<iLeasingCompany>): Promise<void> => {
  const leasingCompanyRef = getLeasingCompanyRefById(id);
  if (!leasingCompanyRef) return;
  const res = await updateDoc(leasingCompanyRef, value);
  return res;
};

export const getLeasingCompaniesCount = async () => {
  const leasingCompaniesRef = getLeasingCompaniesRef();
  if (!leasingCompaniesRef) return 0;
  const count = await getCountFromServer(leasingCompaniesRef);
  return count.data().count || 0;
};

export const getLeasingOffersRef = () => {
  const leasingOffers = collection(db, 'leasingOffers');
  return leasingOffers;
};

export const getLeasingOffersCount = async () => {
  const leasingOffersRef = getLeasingOffersRef();
  if (!leasingOffersRef) return 0;
  const count = await getCountFromServer(leasingOffersRef);
  return count.data().count || 0;
};

export const getCancelledOffersCountByHouseId = async (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return 0;
  const houseOffersRef = getOffersRefByHouseId(customerId, houseId);
  if (!houseOffersRef) return 0;
  const q = query(houseOffersRef, where('cancelled', '==', true));
  const count = await getCountFromServer(q);
  return count.data().count || 0;
};

export const getAcceptedOffersCountByHouseId = async (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return 0;
  const houseOffersRef = getOffersRefByHouseId(customerId, houseId);
  if (!houseOffersRef) return 0;
  const q = query(houseOffersRef, where('isAssigned', '==', true));
  const count = await getCountFromServer(q);
  return count.data().count || 0;
};

export const getRejectedOffersCountByHouseId = async (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return 0;
  const houseOffersRef = getOffersRefByHouseId(customerId, houseId);
  if (!houseOffersRef) return 0;
  const q = query(houseOffersRef, where('rejected', '!=', false));
  const count = await getCountFromServer(q);
  return count.data().count || 0;
};

export const getCustomersRef = () => {
  const customers = collection(db, 'customers');
  return customers;
};

export const getHousesRefByCustomerId = (customerId: string) => {
  const customer = getCustomerRefById(customerId);
  if (!customer) return false;
  const houses = collection(db, 'customers', customerId, 'houses');
  return houses;
};

export const getHousesByCustomerId = async (customerId: string): Promise<iHouse[]> => {
  const customerHousesRef = getHousesRefByCustomerId(customerId);
  if (!customerHousesRef) return [];
  const docs = await getDocs(customerHousesRef);
  const houses: iHouse[] = docs.docs.map((houseData) => ({ id: houseData.id, ...houseData.data() } as iHouse));
  return houses;
};

export const updateACustomerById = async (id: string, value: Partial<iCustomer>): Promise<void> => {
  const customerRef = getCustomerRefById(id);
  if (!customerRef) return;
  const res = await updateDoc(customerRef, value);
  return res;
};

export const getHouseUsersRefByHouseId = (customerId: string, houseId: string) => {
  const houseRef = getHouseRefById(customerId, houseId);
  if (!houseRef) return false;
  const houseUsers = collection(houseRef, 'users');
  return houseUsers;
};

export const addCustomer = async (value: Partial<iCustomer>): Promise<string | false> => {
  const customersRef = getCustomersRef();
  if (!customersRef) return false;
  const res = await addDoc(customersRef, value);
  return res.id;
};

export const getCustomers = async () => {
  const customersRef = getCustomersRef();
  if (!customersRef) return false;
  const docs = await getDocs(customersRef);
  return docs;
};

export const getAllUsersFromHouse = async (customerId: string, houseId: string) => {
  const allUsersRef = collection(db, 'customers', customerId, 'houses', houseId, 'users');
  if (!allUsersRef) return false;
  const docs = await getDocs(allUsersRef);
  return docs;
};

export const getAllCustomersWithHouses = async () => {
  const returnValue: { customer: iCustomer; houses: iHouse[] }[] = [];
  const customers = await getCustomers();
  if (!customers) return false;
  const customersDocs = customers.docs;
  for (let i = 0; i < customersDocs.length; i++) {
    const customer = customersDocs[i];
    // eslint-disable-next-line no-await-in-loop
    const houses = (await getHousesByCustomerId(customer.id)) || [];
    returnValue.push({ customer: { id: customer.id, ...customer.data() } as iCustomer, houses });
  }
  return returnValue;
};

export const getHomeCareRef = () => {
  const homeCare = collection(db, 'homeCare');
  return homeCare;
};

export const getHomeCareCollection = async () => {
  const homeCareRef = getHomeCareRef();
  if (!homeCareRef) return false;
  const docs = await getDocs(homeCareRef);
  return docs;
};

export const getHomeCareRefById = (id: string): DocumentReference<DocumentData> | false => {
  const homeCareCustomerId = id;
  if (!homeCareCustomerId) return false;
  const homeCareCustomer = doc(db, 'homeCare', homeCareCustomerId);
  return homeCareCustomer;
};

export const updateHomeCareById = async (id: string, value: Partial<iHomeCare>): Promise<void> => {
  const homeCareRef = getHomeCareRefById(id);
  if (!homeCareRef) return;
  const res = await updateDoc(homeCareRef, value);
  return res;
};

export const getAllHouseDepartments = async (customerId: string, houseId: string) => {
  const houseDepartmentsRef = collection(db, 'customers', customerId, 'houses', houseId, 'departments');
  if (!houseDepartmentsRef) return false;
  const docs = await getDocs(houseDepartmentsRef);
  return docs.docs.map((d) => ({ id: d.id, ...d.data() } as iDepartment));
};

export const getAllHouseQualifications = async (customerId: string, houseId: string) => {
  const houseQualificationsRef = collection(db, 'customers', customerId, 'houses', houseId, 'qualifications');
  if (!houseQualificationsRef) return false;
  const docs = await getDocs(houseQualificationsRef);
  return docs.docs.map((d) => ({ id: d.id, ...d.data() } as iQualification));
};

export const addBlankReleaseNote = async (): Promise<string> => {
  const releaseNotesDocuments = doc(collection(db, 'releaseNotes'));
  return releaseNotesDocuments.id;
};

export const updateReleaseNoteById = async (id: string, value: Partial<iReleaseNote>): Promise<void> => {
  const releaseNoteRef = doc(db, 'releaseNotes', id);
  if (!releaseNoteRef) return;
  const res = await setDoc(releaseNoteRef, value);
  return res;
};

export const deleteReleaseNoteById = async (id: string): Promise<void> => {
  const releaseNoteRef = doc(db, 'releaseNotes', id);
  if (!releaseNoteRef) return;
  const res = await deleteDoc(releaseNoteRef);
  return res;
};

export const getCustomerReleaseNotesRef = () => {
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!tenantId) return false;
  if (!customerId) return false;
  if (!uid) return false;
  const customerReleaseNotes = collection(
    db,
    'customers',
    customerId,
    'houses',
    tenantId,
    'users',
    uid,
    'customerReleaseNotes',
  );
  return customerReleaseNotes;
};

export const getCustomerReleaseNotesRefByHouseId = (customerId: string, houseId: string) => {
  if (!customerId || !houseId) return false;
  const customerReleaseNotes = collection(db, 'customers', customerId, 'houses', houseId, 'users');
  return customerReleaseNotes;
};

export const checkIfCustomerReleaseNotesExists = async (noteId: string): Promise<boolean> => {
  const notesRef = getCustomerReleaseNotesRef();
  if (!notesRef) return false;

  const docRef = doc(notesRef, noteId);

  const docSnapshot = await getDoc(docRef);

  return docSnapshot.exists();
};

export const addCustomerReleaseNotes = async (
  docId: string,
  value: Partial<iCustomerNews>,
): Promise<string | false> => {
  const notesRef = getCustomerReleaseNotesRef();
  if (!notesRef) return false;

  const docRef = doc(notesRef, docId);
  const exists = await checkIfCustomerReleaseNotesExists(docId);

  if (exists) return false;
  await setDoc(docRef, value);

  return docId;
};

export const updateCustomerReleaseNotesByInternalId = async (
  newsId: string,
  value: Partial<iCustomerNews>,
): Promise<void> => {
  const notesRef = getCustomerReleaseNotesRef();
  if (!notesRef) return;

  const docRef = doc(notesRef, newsId);

  await updateDoc(docRef, value);
};

export const deleteCustomerReleaseNotesByNewsId = async (noteId: string): Promise<void> => {
  const allCustomers = await getAllCustomersWithHouses();
  if (!allCustomers) return;

  const fetchUsersPromises: Promise<QuerySnapshot<DocumentData>>[] = [];

  allCustomers.forEach((customer) => {
    customer.houses.forEach((houseId) => {
      const usersRef = getCustomerReleaseNotesRefByHouseId(customer.customer.id, houseId.id);
      if (usersRef) {
        fetchUsersPromises.push(getDocs(usersRef));
      }
    });
  });
  const usersSnapshots = await Promise.all(fetchUsersPromises);

  const deletionPromises = usersSnapshots.flatMap((usersSnapshot) =>
    usersSnapshot.docs.map((userDoc: { id: string }) => {
      const ref = collection(
        usersSnapshot.query as CollectionReference<DocumentData>,
        userDoc.id,
        'customerReleaseNotes',
      );
      const notesDocRef = doc(ref, noteId);
      return deleteDoc(notesDocRef);
    }),
  );

  await Promise.all(deletionPromises);
};

export const getReleaseNotesRef = () => {
  return collection(db, 'releaseNotes');
};

export const addBlankNews = async (): Promise<string> => {
  const releaseNotesDocuments = doc(collection(db, 'news'));
  return releaseNotesDocuments.id;
};

export const updateNewsById = async (id: string, value: Partial<iNews>): Promise<void> => {
  const releaseNoteRef = doc(db, 'news', id);
  if (!releaseNoteRef) return;
  const res = await setDoc(releaseNoteRef, value);
  return res;
};

export const deleteNewsById = async (id: string): Promise<void> => {
  const releaseNoteRef = doc(db, 'news', id);
  if (!releaseNoteRef) return;
  const res = await deleteDoc(releaseNoteRef);
  return res;
};

export const getCustomerNewsRef = () => {
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!tenantId) return false;
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = collection(db, 'customers', customerId, 'houses', tenantId, 'users', uid, 'customerNews');
  return customerNews;
};

export const getCustomerNewsRefByHouseId = () => {
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  if (!tenantId) return false;
  if (!customerId) return false;
  const customerNews = collection(db, 'customers', customerId, 'houses', tenantId, 'users');
  return customerNews;
};

export const checkIfCustomerNewsExists = async (noteId: string): Promise<boolean> => {
  const newsRef = getCustomerNewsRef();
  if (!newsRef) return false;

  const docRef = doc(newsRef, noteId);

  const docSnapshot = await getDoc(docRef);

  return docSnapshot.exists();
};

export const addCustomerNews = async (docId: string, value: Partial<iCustomerNews>): Promise<string | false> => {
  const newsRef = getCustomerNewsRef();
  if (!newsRef) return false;

  const docRef = doc(newsRef, docId);
  const exists = await checkIfCustomerNewsExists(docId);

  if (exists) return false;
  await setDoc(docRef, value);

  return docId;
};

export const updateCustomerNewsByInternalId = async (newsId: string, value: Partial<iCustomerNews>): Promise<void> => {
  const newsRef = getCustomerNewsRef();
  if (!newsRef) return;

  const docRef = doc(newsRef, newsId);

  await updateDoc(docRef, value);
};

export const deleteCustomerNewsByNewsId = async (newsId: string): Promise<void> => {
  const usersRef = getCustomerNewsRefByHouseId();
  if (!usersRef) return;

  const usersSnapshot = await getDocs(usersRef);
  await Promise.all(
    usersSnapshot.docs.map(async (userDoc) => {
      const newsDocRef = doc(usersRef, userDoc.id, 'customerNews', newsId);
      await deleteDoc(newsDocRef);
    }),
  );
};

export const getNewsRef = () => {
  return collection(db, 'news');
};

export const getAPIHouseSettings = async (customerId: string, houseId: string) => {
  const houseRef = doc(db, 'customers', customerId, 'houses', houseId);
  if (!houseRef) return false;
  const apiRef = doc(houseRef, 'settings', 'api');
  if (!apiRef) return false;
  const docData = await getDoc(apiRef);
  if (docData.exists()) return docData.data() as iAPIHouseSettings;
  return false;
};

export const updateAPIHouseSettings = async (
  customerId: string,
  houseId: string,
  value: Partial<iAPIHouseSettings>,
) => {
  const houseRef = doc(db, 'customers', customerId, 'houses', houseId);
  if (!houseRef) return;
  const apiRef = doc(houseRef, 'settings', 'api');
  if (!apiRef) return;
  const res = await setDoc(apiRef, value);
  return res;
};

export const getSyncSateRef = (
  customerId: string,
  houseId: string,
  forWhat: 'shifts' | 'qualifications' | 'departments' | 'employees',
) => {
  if (!customerId || !houseId) return false;
  const syncRef = collection(db, 'customers', customerId, 'houses', houseId, 'logs', 'api-sync', forWhat);
  if (!syncRef) return false;
  return syncRef;
};

export const getExternalCustomerOffersCountById = async (tenantId: string, date: string) => {
  if (!tenantId) return false;
  const externalCustomersRef = collection(db, 'leasingCompanies', tenantId, 'externalOffers');
  const q = query(externalCustomersRef, where('whenString', '==', date), where('status', '!=', 'cancelled'));
  const count = await getCountFromServer(q);
  return count.data().count || 0;
};
