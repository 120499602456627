import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iCustomerReleaseNote } from 'datamodel';

const initialState: iCustomerReleaseNote[] = [];

export const releaseNotesReactionSlice = createSlice({
  name: 'releaseNoteReaction',
  initialState,
  reducers: {
    setReleaseNoteReactionValue: (state, action: PayloadAction<iCustomerReleaseNote[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setReleaseNoteReactionValue, reset } = releaseNotesReactionSlice.actions;

export default releaseNotesReactionSlice.reducer;
