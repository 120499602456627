import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import { collection, deleteDoc, doc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getCustomerReleaseNotesByIdRef = (notesId: string) => {
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!tenantId) return false;
  if (!customerId) return false;
  if (!uid) return false;
  const customerReleaseNotes = doc(
    db,
    'customers',
    customerId,
    'houses',
    tenantId,
    'users',
    uid,
    'customerReleaseNotes',
    notesId,
  );
  return customerReleaseNotes;
};

export const addLikeNote = async (notesId: string) => {
  const ref = getCustomerReleaseNotesByIdRef(notesId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isThumbUp: true,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen des Likes: ', error);
  }
};

export const addLikeCountNote = async (notesId: string) => {
  const notesRef = doc(db, 'notesReactions', notesId);
  await updateDoc(notesRef, {
    thumbUpCount: increment(1),
  });
};

export const removeLikeCountNote = async (notesId: string) => {
  const notesRef = doc(db, 'notesReactions', notesId);
  await updateDoc(notesRef, {
    thumbUpCount: increment(-1),
  });
};

export const addFavoriteCountNote = async (notesId: string) => {
  const notesRef = doc(db, 'notesReactions', notesId);
  await updateDoc(notesRef, {
    favoritesCount: increment(1),
  });
};

export const removeFavoriteCountNote = async (notesId: string) => {
  const notesRef = doc(db, 'notesReactions', notesId);
  await updateDoc(notesRef, {
    favoritesCount: increment(-1),
  });
};

export const removeLikeNote = async (notesId: string) => {
  const ref = getCustomerReleaseNotesByIdRef(notesId);

  if (!ref) return;
  try {
    await updateDoc(ref, {
      isThumbUp: false,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen des Likes: ', error);
  }
};

export const addFavoriteNote = async (notesId: string) => {
  const ref = getCustomerReleaseNotesByIdRef(notesId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isFavorite: true,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen zu Favoriten: ', error);
  }
};

export const removeFavoriteNote = async (notesId: string) => {
  const ref = getCustomerReleaseNotesByIdRef(notesId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isFavorite: false,
    });
  } catch (error) {
    console.error('Fehler beim Entfernen von Favoriten: ', error);
  }
};

export const addBlankNotesReaction = async (): Promise<string> => {
  const releaseNotesDocuments = doc(collection(db, 'notesReactions'));
  return releaseNotesDocuments.id;
};

export const updateNotesReactionById = async (id: string, noteId: string): Promise<void> => {
  const notesReactionRef = doc(db, 'notesReactions', id);
  if (!notesReactionRef) return;
  const res = await setDoc(notesReactionRef, { noteId }, { merge: true });
  return res;
};

export const deleteNotesReactionById = async (id: string): Promise<void> => {
  const notesReactionRef = doc(db, 'notesReactions', id);
  if (!notesReactionRef) return;
  const res = await deleteDoc(notesReactionRef);
  return res;
};
